/* stylelint-disable font-family-name-quotes */

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@800&family=Titillium+Web:wght@400;700&family=Vollkorn:ital,wght@1,700&display=swap');

/* font-family: 'Jost', sans-serif;
font-family: 'Titillium Web', sans-serif;
font-family: 'Vollkorn', serif; */

.App {
  background: url('./images/noise.png');
  background-color: #f7f6f6;
  background-repeat: repeat;
  color: black;

  /* display: flex;
  flex-direction: column; */
  font-family: 'Titillium Web' , sans-serif;
  font-size: 16px;
  line-height: 1.5;
  max-width: 100%;
  min-height: 100vh;
  width: 100vw;
}

.App > .logo {
  color: aliceblue;
  font-family: Montserrat , sans-serif;
  font-weight: 900;
}

input {
  font-family: 'Titillium Web' , sans-serif;
  font-size: 16px;
}

.container_page {
  left: 0;
  max-width: 100%;
  overflow: hidden;
  width: 100vw;
}

.container_page_checkout {
  display: grid;
  grid-template-rows: repeat(2, auto) 1fr;
  left: 0;
  max-width: 100%;
  min-height: 100vh;
  overflow: hidden;
  width: 100vw;
}

.container_login {
  background: url('./images/noise.png');
  background-color: oldlace;
  background-repeat: repeat;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;
  min-height: 100vh;
  padding: 40px 0;
  width: 100%;
}


.container_products {

  /* background-color: blue; */
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 40px 2px;
  grid-template-columns: repeat(4, 1fr);
  margin: auto;
  max-width: 786px;
  padding: 40px 0;
  width: calc(100vw - 20vw);
}

/* .container_products > div {
  width: 100%;
} */

.container_sales, .container_sales_details, .container_orders, .container_orders_all, .container_manage_admin {
  margin: auto;
  max-width: 800px;
  width: 60vw;
}

.container_sales, .container_orders, .container_orders_all, .container_manage_admin {

  /* background-color: tomato; */
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-height: 25vh;
  width: 100%;
}

.container_sales_details, .container_orders, .container_orders_all, .container_manage_admin {
  height: 100%;
  padding: 40px 0;
  position: relative;
}

.container_sales_details:before {
  background-color: #e3e3e3;
  content: '';
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
}

.container_sales > div:last-of-type:after, .container_orders > div:last-of-type:after {
  border-bottom: 1px black solid;
  bottom: -20px;
  content: '';
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.container_orders_all {

  /* background-color: blue; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 65px;
  padding: 0 20px 40px;
}

@media only screen and ( max-width : 425px ) {

  .container_products {

    /* background-color: rgb(199, 235, 19); */
    grid-template-columns: 50% 50%;
    max-width: initial;
    padding-bottom: 250px;
    width: calc(100vw - 20px);
  }

  .container_orders_all {

    /* background-color: rgb(0, 255, 8); */
    display: grid;
    grid-template-columns: 1fr;
  }

  .container_sales {
    padding: 60px 20px 0 10px;
  }

  .container_orders {
    padding: 60px 10px 0;
  }
}

@media only screen and ( min-width : 426px ) and ( max-width : 700px ) {

  .container_products {

    /* background-color: rgb(199, 235, 19); */
    grid-template-columns: repeat(2, 1fr);
    max-width: 402px;
    padding-bottom: 250px;
  }

  .container_orders_all {

    /* background-color: rgb(0, 255, 8); */
    display: grid;
    grid-template-columns: 1fr;
  }

}

@media only screen and ( min-width : 701px ) and ( max-width : 919px ) {

  .container_products {

    /* background-color: violet; */
    grid-template-columns: repeat(3, 1fr);
    max-width: 604px;
    padding-bottom: 250px;
  }

  .container_orders_all {

    /* background-color: rgb(0, 255, 179); */
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
}

@media only screen and ( min-width : 701px ) and ( max-width : 1300px ) {

  .container_products {
    padding-bottom: 250px;
  }
}

@media only screen and ( max-width : 920px ) {

  .container_login {
    grid-template-columns: 1fr;
    grid-template-rows: 250px calc(100vh - 250px);
  }
}

