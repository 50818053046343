.container_CheckoutElement {
  display: grid;
  grid-template-columns: 50px 3fr 1fr repeat(2, 100px);
  height: 80px;
  padding: 20px 90px 20px 20px;
  position: relative;
  width: 100%;
}

.container_CheckoutElement:before {
  background-color: #d9d9d9;
  border-radius: 10px;
  bottom: 0;
  content: '';
  height: 100%;
  position: absolute;
  width: 100%;
}

.container_item_number, .container_name, .container_quantity {
  align-items: center;
  display: flex;
  height: 100%;
}

.container_item_number {
  font-family: Vollkorn , serif;
  font-size: 29px;
  font-weight: 800;
}

.container_unit_price, .container_sub_total {
  align-items: center;
  display: flex;
  height: 100%;
  padding-left: 24px;
}

.container_unit_price:before, .container_sub_total:before {
  content: 'R$';
  left: 0;
  position: absolute;
}

.container_sub_total {
  font-weight: 700;
}

.container_image {
  bottom: 0;
  height: 116px;
  position: absolute;
  right: 15px;
  width: 60px;
}

.image_product {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.rm_item {
  background-color: black;
  background-image: url('./img/add_icon.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  border: 4px solid black;
  border-radius: 100%;
  cursor: pointer;
  height: 40px;
  overflow: hidden;
  position: absolute;
  right: -20px;
  text-indent: -1000%;
  top: -20px;
  transform: rotate(45deg);
  width: 40px;
  z-index: 2;
}

@media only screen and ( max-width : 425px ) {

  .container_CheckoutElement {
    grid-template-columns: auto 1fr 50px 1fr;
    height: 80px;
    justify-content: center;
    padding: 10px 63px 10px 10px;
    position: relative;
    width: 100%;
  }

  .container_image {
    bottom: 0;
    height: 116px;
    position: absolute;
    right: 3px;
    width: 60px;
  }

  .container_CheckoutElement:before {
    left: 0;
  }

  .container_item_number {
    font-family: Vollkorn , serif;
    font-size: 29px;
    font-weight: 800;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    justify-content: center;
    position: absolute;
  }

  .container_name {
    font-size: 16px;
    font-weight: 900;
    grid-column: 2 / 5;
    grid-row: 1 / 2;
    justify-content: center;
  }

  .container_unit_price {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    justify-content: center;
    padding-left: 0;
  }

  .container_sub_total {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
    justify-content: center;
    padding-left: 0;
  }

  .container_unit_price:before, .container_sub_total:before {
    content: 'R$';
    padding-right: 5px;
    position: relative;
  }

  .container_quantity {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    justify-content: center;
    position: relative;
    z-index: 1;
  }

  .container_quantity:after {
    content: 'x ';
    margin-left: 3px;
  }

  .container_quantity:before {
    background-color: white;
    border-radius: 5px;
    content: '';
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: -1;
  }

  .rm_item {
    background-color: transparent;
    border: 0 solid transparent;
    border-radius: 0;
    bottom: 0;
    cursor: pointer;
    height: 40px;
    overflow: inherit;
    position: absolute;
    right: 0;
    text-indent: -100000%;
    top: inherit;
    transform: rotate(0deg);
    width: 40px;
    z-index: 2;
  }


  .rm_item:before {
    background-color: black;
    border: 4px solid black;
    border-radius: 20px 0 10px;
    bottom: 0;
    content: '';
    height: 40px;
    position: absolute;
    right: 0;
    width: 40px;
  }

  .rm_item:after {
    background-image: url('./img/add_icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    border: 4px solid transparent;
    border-radius: 100%;
    bottom: 0;
    content: '';
    cursor: pointer;
    height: 40px;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-indent: -1000%;
    transform: rotate(45deg);
    width: 40px;
  }
}
