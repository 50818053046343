.container_order_main {
  margin: auto;
  max-width: 500px;
  min-width: 352px;
  width: 100%;
}

.order_id {
  font-size: 14px;
  font-weight: 900;
  text-align: left;
  text-transform: uppercase;
}

.status {
  align-items: center;
  background-color: #d9d9d9;
  border: none;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  display: grid;
  font-family: Titillium Web , sans-serif;
  font-size: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 40px 40px;
  height: 100px;
  line-height: 1.5;
  padding: 10px 150px 10px 30px;
  position: relative;
  width: 100%;
}

.Pendente, .Preparando, .EmTrânsito, .Entregue, .Finalizado {
  align-items: flex-end;
  display: flex;
  font-weight: 900;
  height: 100px;
  justify-content: center;
  padding: 20px 5px 10px;
  position: absolute;
  right: 20px;
  top: -20px;
  width: 100px;
  z-index: 1;
}

.Pendente:before, .Preparando:before, .EmTrânsito:before, .Entregue:before {
  background-color: black;
  content: '';
  height: 20px;
  left: -8px;
  position: absolute;
  top: 0;
  transform: skewX(326deg);
  width: 50%;
  z-index: -1;
}

.Pendente:after, .Preparando:after, .EmTrânsito:after, .Entregue:after {
  border-radius: 0 0 5px 5px;
  box-shadow: -2px 5px 8px 0 rgb(0 0 0 / 50%);
  content: '';
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.Pendente:after {
  background: url('./img/icone_pendente.svg') , url('./img/textura.png');
  background-color: #bbb;
  background-position: center 4px;
  background-repeat: no-repeat , repeat;
  background-size: 65% , 100%;
}

.Preparando:after {
  background: url('./img/icone_preparando.svg') , url('./img/textura.png');
  background-color: #f37f2d;
  background-position: center -1px;
  background-repeat: no-repeat , repeat;
  background-size: 75% , 100%;
}

.EmTrânsito:after {
  background: url('./img/icone_transito.svg') , url('./img/textura.png');
  background-color: #fdd932;
  background-position: center 1px;
  background-repeat: no-repeat , repeat;
  background-size: 80% , 100%;
}

.Entregue:after {
  background: url('./img/icone_entregue.svg') , url('./img/textura.png');
  background-color: #d6ff06;
  background-position: center -6px;
  background-repeat: no-repeat , repeat;
  background-size: 80% , 100%;
}

.date {
  text-align: right;
}

.price {
  align-items: center;
  border-top: 2px dotted white;
  display: flex;
  font-family: Vollkorn , serif;
  font-size: 29px;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  justify-content: space-between;
  position: relative;
}

.label_price {
  font-size: 19px;
}

.currency {
  position: relative;
}

.currency:before {
  bottom: 0;
  content: 'R$';
  font-size: 16px;
  left: 0;
  position: absolute;
  transform: translate(calc((100% + 4px) * -1), -8px);
}

@media only screen and ( max-width : 426px ) {

  .status {
    display: flex;
    flex-direction: column;
  }

  .container_order_main {
    margin: auto;
    max-width: 500px;
    min-width: 268px;
    width: 100%;
  }

  .label_price {
    display: none;
  }

  .price {
    display: flex;
    flex-direction: column;
    font-size: 23px;
  }

  .currency:before {
    bottom: 0;
    content: 'R$';
    font-size: 16px;
    left: 0;
    position: absolute;
    transform: translate(calc((100% + 4px) * -1), -4px);
  }
}
