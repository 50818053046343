/* stylelint-disable font-family-name-quotes */
.container_nav_main {
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

.container_section_user {
  align-items: center;
  background: url('../../images/bg.gif');
  display: flex;
  height: 124px;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
}

.container_logo {
  background: url('../../images/logo.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 180%;
  overflow: hidden;
  text-indent: -1000%;
  transform: translate(-52px, 34px);
  width: 300px;
}

.costumer, .exit {

  /* background-color: rgba(0, 0, 0, 0); */
  border-style: none;
  height: 30px;
}

.costumer {
  align-items: center;
  align-self: flex-start;

  /* background: black; */
  border-radius: 20px;
  color: black;
  display: flex;
  font-size: 20px;
  font-weight: 900;
  height: 40px;
  justify-content: center;
  padding: 15px;
}

.exit {
  background: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  border-radius: 50%;

  /* background-image: url('./img/exit.svg'); */
  color: white;
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  text-transform: uppercase;

  /* text-indent: -1000%; */
  width: 50px;
}

.container_buttons_menu {
  display: flex;
  gap: 40px;
  justify-content: flex-start;
  left: 19px;
  min-height: 40px;
  padding: 10px 10px 0;
}

.container_buttons_menu > .orders, .container_buttons_menu > .products {
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  cursor: pointer;
  font-family: 'Jost' , sans-serif;
  font-size: 20px;
  font-weight: 400;
  height: 30px;
  text-transform: uppercase;
}

/* Elementos que ficam acima dos botões para indicar que o item de menu foi selecionado */
.container_buttons_menu > .products.selected:after {
  background-color: black;
  content: '';
  height: 5px;
  left: 0;
  position: absolute;
  top: -10px;
  width: 100%;
}

.container_buttons_menu > .orders.selected:after {
  background-color: black;
  content: '';
  height: 5px;
  left: 0;
  position: absolute;
  top: -10px;
  width: 100%;
}

@media only screen and ( max-width : 485px ) {

  .container_section_user {
    display: grid;
    grid-template-columns: 1fr 70px;
    grid-template-rows: 30px auto;
    padding: 0 10px 10px;
  }

  .costumer {
    color: white;
    font-size: 18px;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    justify-self: flex-start;
    position: relative;
    z-index: 2;
  }

  .costumer:before {
    background: black;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-25px);
    width: 150vw;
    z-index: -1;
  }

  .container_logo {
    background-position: center;
    background-size: 50%;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    height: 100%;
    left: 11px;
    transform: translate(-12px, 4px);
    width: 100%;
  }

  .exit {
    background-color: none;
    background-color: transparent;
    color: #ffc700;
    font-family: 'Titillium Web' , sans-serif;
    font-size: 16px;
    font-weight: 900;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    height: 100%;
    width: 100%;
    z-index: 3;
  }
}
