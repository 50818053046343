.container_checkout_details {
  position: relative;
  width: 100%;
}

.form_details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 40px;
}

.button_submit_order {
  background-color: #ffc700;
  border-radius: 10px;
  border-style: none;
  cursor: pointer;
  font-family: Vollkorn , serif;
  font-size: 22px;
  height: 80px;
  left: 50%;
  line-height: 1.3;
  margin-top: 40px;
  padding: 0 20px;
  transform: translateX(-50%);
  width: 100%;
}

.seller > em, .adress > em, .number > em {
  color: black;
  display: flex;
  flex-direction: column;
  font-family: Titillium Web , sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  padding: 0 0 10px 5px;
}

.seller > select {
  appearance: none;
  background-color: white;
  border: 0;
  border-radius: 5px;
  box-shadow: none;
  cursor: pointer;
  height: 50px;
  outline: 0;
  padding: 0 20px;
  position: relative;
  width: 100%;
}

/* https://codepen.io/raubaca/pen/VejpQP */
.seller:after {
  background-color: black;
  border-radius: 0 5px 5px 0;
  bottom: 0;
  color: white;
  content: '\25BC';
  height: 50px;
  padding: 1em;
  pointer-events: none;
  position: absolute;
  right: 0;
  transition: 0.25s all ease;
}

.input_address, .input_address_number {
  border: 0;

  /* border: 1px black solid; */
  border-radius: 5px;
  height: 50px;
  padding: 0 20px;
  width: 100%;
}
