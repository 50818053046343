/* stylelint-disable font-family-name-quotes */
.cart_button {
  background-color: #ffc700;
  border-radius: 10px;
  border-style: none;
  bottom: 80px;
  cursor: pointer;
  font-family: 'Titillium Web' , sans-serif;
  font-size: 16px;
  height: 80px;
  line-height: 1.3;
  padding: 0 20px;
  position: fixed;
  right: 40px;
  width: 180px;
  z-index: 1000;
}

.checkout_value {
  font-family: 'Vollkorn' , serif;
  font-size: 34px;
  font-weight: 800;
}

.curency {
  font-family: 'Vollkorn' , serif;
  font-size: 16px;
  padding-right: 5px;
  transform: translateY(-5px);
}

.cart_button:disabled {
  cursor: not-allowed;
}

.cart_button:after {
  background-color: black;
  background-image: url('./img/chart.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  border-radius: 100%;
  content: '';
  height: 60px;
  position: absolute;
  right: -25px;
  top: -25px;
  width: 60px;
}
