/* stylelint-disable max-line-length */
.order_details {
  align-items: center;
  background-color: black;
  color: white;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 3fr 1fr 200px;
  height: 80px;
  justify-content: space-between;
  padding: 0 0 0 20px;
  position: relative;
  width: 100%;
}

/* tela seller apresenta mais botoes */
.order_details.seller {
  grid-template-columns: 1fr 1fr 2fr 2fr 200px;
}

.seller_name:before {
  content: 'Vendedor(a): ';
  font-weight: 900;
}

.order_number {
  font-weight: 900;
}

/* BOTÃO DE STATUS  */
.status {
  align-items: center;
  color: black;
  display: flex;
  font-weight: 900;
  height: 80px;
  justify-content: center;
  padding: 0 10px 0 90px;
  position: relative;
}

/* BOTÃO DE STATUS  na tela seller */
.order_details.seller > .status {
  grid-column: 5 / 6;
  grid-row: 1 / 2;
}

.status.Pendente, .status.Pendente:before {
  background: url('./img/textura.png');
  background-color: #bbb;
  background-repeat: repeat;
}

.status.Preparando, .status.Preparando:before {
  background: url('./img/textura.png');
  background-color: #f37f2d;
  background-repeat: repeat;
}

.status.EmTrânsito, .status.EmTrânsito:before {
  background: url('./img/textura.png');
  background-color: #fdd932;
  background-repeat: repeat;
}

.status.Entregue, .status.Entregue:before {
  background: url('./img/textura.png');
  background-color: #d6ff06;
  background-repeat: repeat;
}

.status.Pendente:after, .status.Preparando:after, .status.EmTrânsito:after, .status.Entregue:after {
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  height: 80px;
  left: 0%;
  position: absolute;
  top: 0;
  width: 80px;
}

.status.Pendente:after {

  /* background-image: url('./img/icone_pendente.svg'); */
  background: url('./img/icone_pendente.svg');
  background-repeat: no-repeat;
  background-size: 80%;
}

.status.Preparando:after {
  background-image: url('./img/icone_preparando.svg');
  background-size: 90%;
}

.status.EmTrânsito:after {
  background-image: url('./img/icone_transito.svg');
  background-size: 90%;
}


.status.Entregue:after {
  background-image: url('./img/icone_entregue.svg');
  background-size: 100%;
}

.status.Pendente:before, .status.Preparando:before, .status.EmTrânsito:before, .status.Entregue:before {
  bottom: 0;
  content: '';
  height: 8px;
  position: absolute;
  right: 0;
  width: 800px;
}


/* BOTÃO MARCAR COMO ENTREGUE  */
.slider_delivered_checkbox_container {
  background: rgb(66, 66, 66);
  border-radius: 50px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5) , 0 1px 0 rgba(255, 255, 255, 0.2);
  height: 12px;
  margin: 20px auto;
  position: absolute;
  right: 20px;
  top: -50px;
  width: 50px;
}

.slider_delivered_checkbox_container.Pendente, .slider_delivered_checkbox_container.Preparando {
  opacity: 0.4;
}

.delivered_checkbox {
  display: block;
  height: 100%;
  width: 50px;
}

.delivered_checkbox.Pendente, .delivered_checkbox.Preparando {
  cursor: not-allowed;
}

.delivered_checkbox_text {
  color: black;
  font-weight: 900;
  position: absolute;
  text-align: right;
  transform: translate(calc((100% + 10px) * -1), -7px);
  width: 166px;
}

.delivered_slider_item {
  background-color: #ffc700;
  border-radius: 50%;
  height: 24px;
  left: -6px;
  position: absolute;
  top: -6px;
  transition: all 0.4s ease;
  width: 24px;
}

.delivered_input ~ .delivered_slider_item {
  left: 0;
}

.delivered_input:checked ~ .delivered_slider_item {
  left: 40px;
}

.delivered_input {
  visibility: hidden;
}

/* Botões "preparar pedido" e "saiu para entrega" em tela de vendedor */
.button_preparing_check, .button_dispatch_check {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Titillium Web , sans-serif;
  font-size: 16px;
  font-weight: 200;
  height: 45%;
}

.button_preparing_check:disabled, .button_dispatch_check:disabled {
  color: #a7a0a0;
  cursor: not-allowed;
}

@media only screen and ( max-width : 425px ) {

  .order_details {
    align-items: center;
    background-color: black;
    border-radius: 10px;
    color: white;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 100px 1fr;
    height: inherit;
    justify-content: space-between;
    padding: 10px 0 10px 10px;
    position: relative;
    width: 100%;
  }

  .order_number {

    /* background-color: #d6ff06; */
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    /* display: none; */
  }

  .seller_name {

    /* background-color: violet; */
    grid-column: 2 / 3;
    grid-row: 2 / 3;

    /* display: none; */
  }

  .order_data {

    /* background: blue; */
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  .status {
    align-items: flex-end;
    align-self: end;
    border-radius: 10px 0 0 10px;
    height: 100%;
    justify-content: center;
    padding: 10px 0;
    position: absolute;
    width: 100px;
  }

  .status.Pendente:after {
    background: url('./img/icone_pendente.svg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 80%;
  }

  .status.Preparando:after {
    background-image: url('./img/icone_preparando.svg');
    background-position: right center;
    background-size: 90%;
  }

  .status.EmTrânsito:after {
    background-image: url('./img/icone_transito.svg');
    background-position: 15px center;
    background-size: 90%;
  }

  .status.Entregue:after {
    background-image: url('./img/icone_entregue.svg');
    background-position: 15px center;
    background-size: 100%;
  }

  .status.Pendente:before, .status.Preparando:before, .status.EmTrânsito:before, .status.Entregue:before {
    content: '';
    display: none;
  }
}
