.conteiner_totalPrice {
  display: grid;
  font-family: Vollkorn , serif;
  font-size: 29px;
  font-weight: 800;
  grid-template-columns: 50px 3fr 1fr repeat(2, 100px);
  padding: 0 90px 20px 20px;
  position: relative;
  width: 100%;
}

.total_price {
  font-weight: 900;
  grid-column: 5 / 6;
  grid-row: 1 / 2;

  /* margin-left: 15px; */
  position: relative;
}

.total_price:before {
  content: 'R$';
  font-size: 14px;
  font-weight: 900;
  left: -20px;
  position: absolute;
  top: 9px;
}

@media only screen and ( max-width : 425px ) {

  .conteiner_totalPrice {
    padding: 0;
  }
}
